import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"

const Events = () => (

  <Layout>
    <SEO title="Rum Times" />
  
    <PostHero
        title="Rum Times"
        description=""
        image={require('../images/Rum-Times-4-2500.jpg')}
    />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="topline-statement image-link">
                <p>Get excited to enjoy RUM TIMES, our first mixed-reality-IRL-exhibition. Coming soon to the Museum of Distilled Spirits!</p> 
                
                <p><a href="https://www.instagram.com/plantation.rum/?hl=en"><img src={require("../images/plantation-rum-logo.jpg")} alt='Plantation Rum: Barbados best kept secret.'/></a></p>

            </div>            

         </div>

      </div>

  </Layout>
)

export default Events